import { useState } from "react";
import {
  //  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import axios from "axios";
import { getBaseUrl } from "../../utils";
import { BASE_URL_STAGING, BASE_URL_PRODUCTION } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const languageOptions = [
  {
    value: BASE_URL_PRODUCTION,
    label: "Production",
  },
  {
    value: BASE_URL_STAGING,
    label: "Development",
  },
];

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "demo@devias.io",
              password: "Password123",
              baseURL: getBaseUrl() || BASE_URL_STAGING,
              error: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string()
                .max(255)
                .required("Password is required"),
            })}
            onSubmit={(value, { setSubmitting }) => {
              axios
                .post(`${value.baseURL}/user/login`, {
                  email: value.email,
                  password: value.password,
                })
                .then((result) => {
                  console.log(result);
                  localStorage.setItem("TOKEN", result.data.refreshToken);
                  localStorage.setItem("BASE_URL", value.baseURL);

                  navigate("/app/dashboard", { replace: true });
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false);

                  setError("Invalid credentials");
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>

                  <TextField
                    label="Base URL"
                    select
                    error={Boolean(touched.baseURL && errors.baseURL)}
                    fullWidth
                    helperText={touched.baseURL && errors.baseURL}
                    margin="normal"
                    name="baseURL"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.baseURL}
                    variant="outlined"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {languageOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {error && <span>{error}</span>}
                {/* <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?{" "}
                  <Link component={RouterLink} to="/register" variant="h6">
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
