import { useState, useCallback } from "react";
import axios from "axios";
import { getBaseUrl, getToken } from "../utils";

export const usePost = (url) => {
  const [result, setResult] = useState({
    data: null,
  });

  const BASE_URL = getBaseUrl();

  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };

  const post = useCallback(
    (payload) => {
      setResult((prevState) => ({ ...prevState }));
      axios
        .post(`${BASE_URL}${url}`, payload, { headers: header })
        .then((result) => {
          setResult({ data: result.data });
        })
        .catch((error) => {
          setResult({ data: null, error });
        });
    },
    [url]
  );
  return [result, post];
};
