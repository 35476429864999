import { TrimArray } from "./index";

const convertBookInfo = (newBook) => {
  const convertedTags =
    (newBook.tags && TrimArray(newBook.tags.split(","))) || [];
  const convertedCategories =
    (newBook.category && TrimArray(newBook.category.split(","))) || [];
  const convertedChapters = [];

  if (newBook.chapters) {
    newBook.chapters.split("'").forEach((chapter) => {
      const name = chapter.split("-")[0] && chapter.split("-")[0].trim();
      const timestamp =
        chapter.split("-")[1] && Number(chapter.split("-")[1].trim());

      if (name && typeof timestamp === "number") {
        convertedChapters.push({ name, timestamp });
      }
    });
  }

  const finalData = {
    ...newBook,
    category: convertedCategories,
    tags: convertedTags,
    chapters: convertedChapters,
    length: Number(newBook.length),
    numberOfPages: Number(newBook.numberOfPages),
  };

  return finalData;
};

export const convertUpdateBook = (book) => {
  const convetedUpdateBook = book;
  const chapters = convetedUpdateBook.chapters
    .map((chapter) => {
      return `'${chapter.name}-${chapter.timestamp}'`;
    })
    .toString();

  delete convetedUpdateBook.totalRating;
  delete convetedUpdateBook.totalVotes;
  delete convetedUpdateBook.type;
  delete convetedUpdateBook.createdAt;
  delete convetedUpdateBook.totalVotes;

  return {
    ...convetedUpdateBook,
    bookId: convetedUpdateBook.id,
    category: convetedUpdateBook.category.toString(),
    tags: convetedUpdateBook.tags.toString(),
    chapters: chapters,
  };
};

export default convertBookInfo;
