import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  TextField,
  Button,
  CardHeader,
  Divider,
  Card,
  Grid,
  CardContent,
} from "@material-ui/core";
import convertBookInfo, {
  convertUpdateBook,
} from "../../../utils/convertBookInfo";

import { usePost } from "../../../hooks/usePost";

const useStyles = makeStyles(() => ({
  root: {
    width: 800,
    height: 780,
    padding: "7px 32px 24px",
    overflow: "auto",
  },
  closeButton: {
    cursor: "pointer",
    fontFamily: "Roboto",
    position: "relative",
    top: "1px",
    fontSize: "23px",
  },
}));

const languageOptions = [
  {
    value: "BG",
    label: "BG",
  },
  {
    value: "UK",
    label: "UK",
  },
  {
    value: "US",
    label: "US",
  },
  {
    value: "EN",
    label: "EN",
  },
];

const visibilityOptions = [
  {
    value: true,
    label: "Visible",
  },
  {
    value: false,
    label: "Hidden",
  },
];

const initialNewBook = {
  title: "",
  voiceActor: "",
  author: "",
  language: "BG",
  genre: "",
  category: "",
  tags: "",
  length: 0,
  numberOfPages: 0,
  chapters: "",
  description: "",
  thumbnailUrl:
    "https://voice.fra1.cdn.digitaloceanspaces.com/covers-small/bookTemplate.jpg",
  pictureUrl:
    "https://voice.fra1.cdn.digitaloceanspaces.com/covers-large/bookTemplate.jpg",
  streamUrl: "",
  isVisible: true,
};

const AddTransactionModal = ({ setIsOpenModal, book, setRefreshData }) => {
  const classes = useStyles();
  const [newBook, setNewBook] = useState(initialNewBook);
  const [readyToAdd, setReadyToAdd] = useState(false);
  const [result, post] = usePost(book ? `/books/update` : `/books`);

  const title = book ? "Update Book " : "Add Book";

  useEffect(() => {
    if (
      newBook.title &&
      newBook.voiceActor &&
      newBook.author &&
      newBook.genre &&
      newBook.streamUrl &&
      newBook.description.length > 9 &&
      newBook.language
    ) {
      setReadyToAdd(true);
    }
  }, [newBook]);

  // check if we have book to update
  useEffect(() => {
    if (book) {
      setNewBook(convertUpdateBook(book));
    }
  }, [book]);

  const onAddBook = () => {
    const data = convertBookInfo(newBook);

    post(data);
  };

  useEffect(() => {
    if (result.data) {
      setNewBook(initialNewBook);
      setRefreshData(true);
      setIsOpenModal(false);
    }
  }, [result]);
  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        action={
          <span
            className={classes.closeButton}
            onClick={() => setIsOpenModal(false)}
          >
            x
          </span>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Title"
              type="text"
              variant="outlined"
              error={!newBook.title}
              value={newBook.title}
              onChange={(e) =>
                setNewBook({ ...newBook, title: e.target.value })
              }
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Voice Actor"
              type="text"
              variant="outlined"
              error={!newBook.voiceActor}
              value={newBook.voiceActor}
              onChange={(e) =>
                setNewBook({ ...newBook, voiceActor: e.target.value })
              }
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Author"
              type="text"
              variant="outlined"
              error={!newBook.author}
              value={newBook.author}
              onChange={(e) =>
                setNewBook({ ...newBook, author: e.target.value })
              }
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Genre"
              type="text"
              variant="outlined"
              error={!newBook.genre}
              value={newBook.genre}
              onChange={(e) =>
                setNewBook({ ...newBook, genre: e.target.value })
              }
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Length"
              type="number"
              variant="outlined"
              value={newBook.length}
              onChange={(e) =>
                e.target.value >= 0 &&
                setNewBook({ ...newBook, length: e.target.value })
              }
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Number Of Pages"
              type="number"
              variant="outlined"
              value={newBook.numberOfPages}
              onChange={(e) =>
                e.target.value >= 0 &&
                setNewBook({ ...newBook, numberOfPages: e.target.value })
              }
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Thumbnail Url"
              type="text"
              variant="outlined"
              value={newBook.thumbnailUrl}
              onChange={(e) =>
                setNewBook({ ...newBook, thumbnailUrl: e.target.value })
              }
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Picture Url"
              type="text"
              variant="outlined"
              value={newBook.pictureUrl}
              onChange={(e) =>
                setNewBook({ ...newBook, pictureUrl: e.target.value })
              }
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Stream Url"
              type="text"
              variant="outlined"
              error={!newBook.streamUrl}
              value={newBook.streamUrl}
              onChange={(e) =>
                setNewBook({ ...newBook, streamUrl: e.target.value })
              }
              required
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Categories"
              type="text"
              variant="outlined"
              value={newBook.category}
              onChange={(e) =>
                setNewBook({ ...newBook, category: e.target.value })
              }
              helperText="Split the caterories with ,"
              multiline
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Tags"
              type="text"
              variant="outlined"
              value={newBook.tags}
              onChange={(e) => setNewBook({ ...newBook, tags: e.target.value })}
              helperText="Split the tags with ,"
              multiline
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              label="Description"
              type="text"
              variant="outlined"
              error={newBook.description.length < 10}
              value={newBook.description}
              onChange={(e) =>
                setNewBook({ ...newBook, description: e.target.value })
              }
              helperText="Description must be longer than or equal to 10 characters."
              required
              multiline
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <TextField
              label="Chapters (name-timestamp)"
              type="text"
              variant="outlined"
              value={newBook.chapters}
              onChange={(e) =>
                setNewBook({ ...newBook, chapters: e.target.value })
              }
              helperText="Example: 'Красавицата-2','Телефона-4...."
              multiline
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              label="Language"
              select
              variant="outlined"
              error={!newBook.language}
              value={newBook.language}
              onChange={(e) =>
                setNewBook({ ...newBook, language: e.target.value })
              }
              required
              SelectProps={{
                native: true,
              }}
            >
              {languageOptions.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.label}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              label="Visibility"
              select
              variant="outlined"
              value={newBook.isVisible}
              onChange={(e) =>
                setNewBook({ ...newBook, isVisible: e.target.value === "true" })
              }
              required
              SelectProps={{
                native: true,
              }}
            >
              {visibilityOptions.map((visibility) => (
                <option key={visibility.value} value={visibility.value}>
                  {visibility.label}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item md={10} xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onAddBook}
              disabled={!readyToAdd}
            >
              {title}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AddTransactionModal.propTypes = {
  setIsOpenModal: PropTypes.func,
  book: PropTypes.object,
};

export default AddTransactionModal;
