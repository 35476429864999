export const TrimArray = (arr) => {
  return arr.map((string) => string.trim());
};

export const getBaseUrl = () => {
  return localStorage.getItem("BASE_URL");
};

export const getToken = () => {
  return localStorage.getItem("TOKEN");
};
