import { useState, useCallback } from "react";
import axios from "axios";
import { getBaseUrl, getToken } from "../utils";

export const useGet = (url) => {
  const [result, setResult] = useState({
    data: null,
  });
  const BASE_URL = getBaseUrl();
  
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };

  const get = useCallback(() => {
    setResult((prevState) => ({ ...prevState }));
    axios
      .get(`${BASE_URL}${url}`, { headers: header })
      .then((result) => {
        setResult({ data: result.data });
      })
      .catch((error) => {
        setResult({ data: null, error });
      });
  }, [url]);

  return [result, get];
};
