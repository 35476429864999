import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useGet } from "../../../hooks/useGet";

const useStyles = makeStyles(() => ({
  root: {},
  negative: {
    color: colors.red[900],
  },
  positive: {
    color: colors.green[600],
  },
  cryptoIcon: {
    width: 25,
    margin: "0px 7px -6px 0",
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const Assets = ({
  className,
  onOpenBook,
  refreshData,
  setRefreshData,
  orderBy,
  ...rest
}) => {
  const classes = useStyles();
  const [books, setBooks] = useState([]);
  const [pagination, setPagination] = useState({});
  const [limit, setLimit] = useState(5);
  const [newPage, setNewPage] = useState(1);
  const [result, get] = useGet(!orderBy ? `/books?page=${newPage}&limit=${limit}` : `/book-metadata?sort=-${orderBy}&page=${newPage}&limit=${limit}`);

  useEffect(() => {
    if (refreshData) {
      get();
      setRefreshData(false);
    }
  }, [limit, newPage, refreshData, orderBy]);

  useEffect(() => {
    if (result.data) {
      setBooks(result.data.books.map(book => {
        if (!book.bookId){ 
          return book
        }
        book.bookId.totalTimes = book.totalTimes
        book.bookId.uniqueTimes = book.uniqueTimes
        book.bookId.totalVotes = book.bookId.voters.length
        book.bookId.id = book.bookId._id
        return book.bookId
      }));
      setPagination(result.data.pagination);
    }
  }, [result]);

  const isAddOrderByTableCell = (value) => {
    return !!orderBy ? <TableCell>{value}</TableCell> : null
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="All Books" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Autor</TableCell>
                <TableCell>Voice Actor</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Pages</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Votes</TableCell>
                <TableCell>Type</TableCell>
                {isAddOrderByTableCell('Total Views')}
                {isAddOrderByTableCell('Unique Views')}
              </TableRow>
            </TableHead>
            <TableBody>
              {books.map((book) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={book.id}
                  onClick={() => onOpenBook(book)}
                >
                  <TableCell>{book.title}</TableCell>
                  <TableCell>{book.author}</TableCell>
                  <TableCell>{book.voiceActor}</TableCell>
                  <TableCell>{book.language}</TableCell>
                  <TableCell>{book.numberOfPages}</TableCell>
                  <TableCell>{book.genre}</TableCell>
                  <TableCell>{book.id}</TableCell>
                  <TableCell>{book.length}</TableCell>
                  <TableCell>{book.totalRating}</TableCell>
                  <TableCell>{book.totalVotes}</TableCell>
                  <TableCell>{book.type}</TableCell>
                  {isAddOrderByTableCell(book.totalTimes)}
                  {isAddOrderByTableCell(book.uniqueTimes)}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: 10000 },
                  ]}
                  count={pagination.totalItems || 0}
                  rowsPerPage={limit}
                  page={pagination.currentPage - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={(e, newPage) => {
                    setNewPage(newPage + 1);
                    setRefreshData(true);
                  }}
                  onChangeRowsPerPage={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setRefreshData(true);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Assets.propTypes = {
  className: PropTypes.string,
  onOpenBook: PropTypes.func,
};

export default Assets;
